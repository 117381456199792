import React from "react";
import styled from "styled-components";
import Router from "next/router";
import { HEXCOLORS } from "../styles/index";

const PrimaryButton = styled.span`
  background-color: ${HEXCOLORS.primary};
  color: ${(props) => props.color};
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  padding: 16px 24px 12px;
  font-size: 16px;
  margin-top: 16px;
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 16px;
  font-family: Nexa;
  font-weight: 600;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${HEXCOLORS.primaryHover};

    color: ${(props) =>
      props.bgcolor === HEXCOLORS.orange ? HEXCOLORS.white : HEXCOLORS.white};
  }
  ${(props) =>
    props.isSmall &&
    `
    font-size: 12px;
    padding: 4px 8px;
    margin: 4px 0;
  `}
  ${(props) => ({ ...props.style })}
`;

const Button = ({
  goTo,
  children,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  isSmall = false,
  style,
  bgcolor = HEXCOLORS.orange,
  color = HEXCOLORS.white,
  external = false,
  toggleMenu = () => {},
}) => {
  return (
    <PrimaryButton
      onClick={() => {
        toggleMenu && toggleMenu(false)
        goTo && Router.push(goTo);
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isSmall={isSmall}
      style={style}
      bgcolor={bgcolor}
      color={color}
    >
      {children}
    </PrimaryButton>
  );
};

export default Button;
