import React from "react";
import styled from "styled-components";
import Router from "next/router";
import { HEXCOLORS } from "../../styles";

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: Nexa;
  font-weight: 500;
  padding: 0 16px;
  font-size: 14px;
  transition: color 0.3s ease;
  &:hover {
    color: ${HEXCOLORS.primary};
  }
  @media (max-width: 768px) {
    padding: 16px;
    font-size: 20px;
    font-weight: bold;
  }
`;

const RouteLink = ({ goTo = "/", children, toggleMenu = () => {}, sub }) => {
  return (
    <Link
      onClick={() => {
        toggleMenu && toggleMenu(false);
        document.body.classList.remove("mobile-nav");
        Router.push(goTo);
      }}
      sub={sub}
    >
      {children}
    </Link>
  );
};

export default RouteLink;
