import { createGlobalStyle } from "styled-components";

export const HEXCOLORS = {
  white: "#ffffff",
  black: "#000000",
  text: "rgb(41,41,41)",
  primary: "#7255eb",
  primaryHover: "#423f8f",
  success: "#51ffb0",
  error: "#ff51a0",
}

export const GlobalStyle = createGlobalStyle`
  `;
