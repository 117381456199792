import React from 'react'
import RouteLink from './RouteLink'
import Router from 'next/router'
import styled from 'styled-components'
import { HEXCOLORS } from '../../styles'
import { Menu as Hamburger, X } from 'react-feather'
import Button from '../Button'
import MobileOnly from '../MobileOnly'
import NotMobile from '../NotMobile'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  z-index: 10;
  height: 45px;
  left: 0;
  display: flex;
  padding: 16px;
  box-sizing: initial;
  align-items: center;
  justify-content: space-between;
  width: 100vw;

  ${props =>
    !props.isActive &&
    `
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  `}

  @media all and (max-width: 768px) {
    ${props =>
      !props.isActive &&
      `
      height: 32px;
      padding: 8px;
    `}
  }
`

const Logo = styled.img`
  transition: height 0.3s ease, width 0.3s ease, left 0.3s ease;
  height: 45px;
  @media all and (max-width: 768px) {
    ${props =>
      !props.isActive &&
      `
      height: 32px;
    `}
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const MobileMenu = styled.div`
  position: fixed;
  top: 16px;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10;
  cursor: pointer;
`

const HamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(50vw + 60px);
  position: fixed;
  left: calc(100vw - 64px);
  top: 20px;
  @media all and (max-width: 768px) {
    ${props =>
      !props.isActive &&
      `
      top: 10px;
    `}
  }
`

const HamburgerOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${HEXCOLORS.primary};
  border-radius: 50%;
  padding: 4px;
  margin-left: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`

const Links = styled.div`
  margin: 0 32px;
  justify-content: flex-end;
`

const MobileMenuActive = styled.div`
  z-index: 9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  min-height: 100vh;
  position: fixed;
  background-image: url(https://products.ls.graphics/mesh-gradients/images/87.-Spacy.jpg);
  background-size: cover;
`

const Menu = () => {
  const [isMobileActive, setIsMobileActive] = React.useState(false)
  const [isActive, setIsActive] = React.useState(true)

  const handleScroll = () => {
    const bodyOffset = document.body.getBoundingClientRect()
    const scrollY = -bodyOffset.top
    if (scrollY > 32) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <MobileOnly>
        <MobileMenu>
          <Wrapper isActive={isActive}>
            <Logo
              src='/images/logo.svg'
              className='logo'
              alt='Kine itterbeek'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                Router.push('/')
              }}
              isActive={isActive}
            />
            <HamburgerMenu isActive={isActive}>
              <HamburgerOuter
                isActive={isActive}
                onClick={() => {
                  setIsMobileActive(!isMobileActive)
                  document.body.classList.toggle('scroll')
                }}
              >
                {!isMobileActive ? (
                  <Hamburger size={20} color={HEXCOLORS.white} />
                ) : (
                  <X size={20} color={HEXCOLORS.white} />
                )}
              </HamburgerOuter>
            </HamburgerMenu>
          </Wrapper>
        </MobileMenu>
        {isMobileActive && (
          <MobileMenuActive>
            <Flex>
              <RouteLink toggleMenu={setIsMobileActive} goTo='#home'>
                Home
              </RouteLink>
              <RouteLink toggleMenu={setIsMobileActive} goTo='#specialisaties'>
                Specialisaties
              </RouteLink>
              <RouteLink toggleMenu={setIsMobileActive} goTo='#team'>
                Team
              </RouteLink>
              <RouteLink toggleMenu={setIsMobileActive} goTo='#behandelingen'>
                Behandelingen
              </RouteLink>
              <Button
                toggleMenu={setIsMobileActive}
                goTo='#contact'
                style={{ margin: '16px 0' }}
              >
                Afspraak maken
              </Button>
            </Flex>
          </MobileMenuActive>
        )}
      </MobileOnly>
      <NotMobile>
        <Wrapper isActive={isActive}>
          <Logo
            src='/images/logo.svg'
            className='logo'
            alt='Kine Itterbeek'
            style={{ cursor: 'pointer' }}
            onClick={() => {
              Router.push('/#home')
            }}
          />
          <Links>
            <RouteLink goTo='/#specialisaties'>Specialisaties</RouteLink>
            <RouteLink goTo='/#team'>Team</RouteLink>
            <RouteLink goTo='/#behandelingen'>Behandelingen</RouteLink>
            <Button goTo='#contact' style={{ margin: '0 16px' }}>
              Contact
            </Button>
          </Links>
        </Wrapper>
      </NotMobile>
    </>
  )
}

export default Menu
