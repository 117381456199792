import React, { Fragment, useEffect } from "react";
import { GlobalStyle } from "../styles";
import styles from "../styles/globals.css";
import { isWindows } from "react-device-detect";
import Menu from "../components/navigation/Menu";
import Head from "next/head";

// Import Swiper styles
import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'

function MyApp({ Component, pageProps }) {
  React.useEffect(() => {
    if (isWindows) {
      document
        .querySelector("meta[name=viewport]")
        .setAttribute(
          "content",
          "width=device-width, initial-scale=" + 1 / window.devicePixelRatio
        );
      document.body.classList.add("windows");
    }
  }, []);

  return (
    <Fragment>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#7255eb"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="theme-color" content="#ffffff"/>
      </Head>
      <GlobalStyle />
      <Menu />
      <Component {...pageProps} />
    </Fragment>
  );
}

export default MyApp;
